import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SHOW_LOADING,
  STOP_LOADING,
  REGISTER_EMAIL,
  REGISTER_ID,
  NOTIFICATION_COUNT,
  REFRESH_NOTIFICATIONS_FUNC,
  REFRESH_ALERTS_FUNC,
  HELP,
  REFRESH_COMPANY_FEATURES,
  ENV_CONF,
  ALERTS_COUNT,
  ASSIGNED_GROUPS,
} from '../constants/Auth'

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  isLoading: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
  email: '',
  notificationsCount: 0,
  alertsCount: 0,
  help: false,
  company: null,
  assignedGroups: localStorage.getItem(ASSIGNED_GROUPS)
    ? JSON.parse(localStorage.getItem(ASSIGNED_GROUPS))
    : [],
}

const auth = (state = initState, action) => {
  switch (action.type) {
    case ENV_CONF:
      return {
        ...state,
        ...action.payload,
      }
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: '/',
        token: action.token,
        name: action.name,
        role: action.role,
        isTrialUser: action.isTrialUser,
        companyId: action.companyId,
        company: action.company,
        assignedGroups: action.assignedGroups || state.assignedGroups,
      }
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      }
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      }
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: '/',
        loading: false,
      }
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case STOP_LOADING: {
      return {
        ...state,
        loading: false,
      }
    }
    case REGISTER_EMAIL: {
      return {
        ...state,
        email: action.email,
      }
    }
    case REGISTER_ID: {
      return {
        ...state,
        onBoardingId: action.onBoardingId,
      }
    }
    case NOTIFICATION_COUNT: {
      return {
        ...state,
        notificationsCount: action.count,
      }
    }
    case REFRESH_NOTIFICATIONS_FUNC: {
      return {
        ...state,
        refreshNotificationFunc: action.refreshFunc,
      }
    }
    case ALERTS_COUNT: {
      return {
        ...state,
        alertsCount: action.count,
      }
    }
    case REFRESH_ALERTS_FUNC: {
      return {
        ...state,
        refreshAlertFunc: action.refreshFunc,
      }
    }
    case REFRESH_COMPANY_FEATURES: {
      return {
        ...state,
        featuresRefetchFunc: action.refreshFunc,
      }
    }
    case HELP: {
      return {
        ...state,
        help: action.help,
      }
    }
    default:
      return state
  }
}

export default auth
